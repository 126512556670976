<template>
<div>
    <div class="h-handle-bg">
        <div class="h-handle-button">
            <p class="h-return">
                <el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
            </p>
            <div class="h-b">
                <el-button type="primary" size="small" @click="add" v-right-code="'Contractinfo:Create'">新增</el-button>
            </div>
            <div class="h-b">
                <el-button type="text" size="small" @click="isDisabled=false;dataSource.IsEdit=true" v-if="isDisabled" v-loading="loadding" v-right-code="'Contractinfo:Create'">编辑</el-button>
            </div>
            <div class="h-b">
                <el-button type="text" size="small" @click="save" v-if="!isDisabled" v-loading="loadding" v-right-code="'Contractinfo:Create'">保存</el-button>
            </div>
        </div>
    </div>
    <div class="form-list">
        <el-tabs type="border-card" v-model="tabActiveName" >
            <el-tab-pane label="基本信息" name="tabBaice">
                <el-form ref="_contractInfoForm" :model="dataSource" :rules="checkRule">
                    <el-collapse v-model.trim="formItems">
                        <el-collapse-item title="基本信息" name="formItem">
                            <el-form-item>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>合同编码：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="Code">
                                        <el-input v-model.trim="dataSource.Code" :maxlength="30" :minlength="4" :disabled="isDisabled" :readonly="isDisabled" placeholder="合同编码："></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>合同名称：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="Name">
                                        <el-input v-model.trim="dataSource.Name" :disabled="isDisabled" :maxlength="50" placeholder="合同名称：" autofocus="autofocus"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>合同类型：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="Type">
                                        <DictionarySelect v-model="dataSource.Type" :disabled="isDisabled" datType='Int' dicTypeCode="ContactTypeCode" @change="typeChange"></DictionarySelect>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>收付类型：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="PaymentType">
                                        <DictionarySelect v-model="dataSource.PaymentType" :disabled="isDisabled" dicTypeCode="PaymentTypeCode" @change="paymentTypeChange"></DictionarySelect>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">是否正式合同：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="IsFormalContract">
                                        <DictionarySelect v-model="dataSource.IsFormalContract" :disabled="isDisabled" dicTypeCode="FormalContractTypeCode" @change="isFormalContractChange"></DictionarySelect>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>合同签订日期：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="SigningDate">
                                        <el-date-picker v-model="dataSource.SigningDate" :disabled="isDisabled" value-format="yyyy-MM-dd HH:mm:ss" type="date">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>合同开始日期：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="StartDate">
                                        <el-date-picker v-model="dataSource.StartDate" :disabled="isDisabled" value-format="yyyy-MM-dd HH:mm:ss" type="date">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>合同结束日期：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="EndDate">
                                        <el-date-picker v-model="dataSource.EndDate" :disabled="isDisabled" value-format="yyyy-MM-dd HH:mm:ss" type="date">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">签订人：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="SigningUserName">
                                        <el-input v-model="dataSource.SigningUserName" :disabled="isDisabled" readonly suffix-icon="el-icon-search" placeholder="请选择签订人" @click.native="onSigningUserClick"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title">结算币种：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="Currency">
                                        <DictionarySelect v-model="dataSource.Currency" :disabled="isDisabled" dicTypeCode="CurrencyTypeCode" @change="currencyChange"></DictionarySelect>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title">备注：</el-col>
                                <el-col :span="21">
                                    <el-form-item prop="Remark">
                                        <el-input type="textarea" :disabled="isDisabled" v-model.trim="dataSource.Remark" :maxlength="300" placeholder="备注"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                        </el-collapse-item>
                        <el-collapse-item title="合同签约主体信息" name="formItem2">
                            <el-form-item>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>我方身份：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="OurIdentity">
                                        <el-select v-model="dataSource.OurIdentity" :maxlength="200" :disabled="isDisabled">
                                            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>我方签订单位：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="SigningUnitName">
                                        <el-input v-model="dataSource.SigningUnitName" readonly suffix-icon="el-icon-search" :disabled="isDisabled" placeholder="我方签订单位" @click.native="onSigningUnitClick"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-table :data="dataSource.CrList" border style="width: 100%">
                                    <el-table-column width="55" type="index" label="序号">
                                    </el-table-column>
                                    <el-table-column prop="ContactNo">
                                        <template slot="header">
                                            <span style="color:red;">*</span>客户名称：
                                        </template>
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.CustomerName" readonly suffix-icon="el-icon-search" :disabled="isDisabled" placeholder="请选择客户" @click.native="onCustomerClick(scope.$index)"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ContactNo">
                                        <template slot="header">
                                            <span style="color:red;">*</span>客户编码：
                                        </template>
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.CustomerCode" disabled></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ContactStatus" label="联系人">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.Contacts" :disabled="isDisabled"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ContactStatus" label="联系人电话">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.Telephone" :disabled="isDisabled"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ContactStatus" label="开发行">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.BankName" :disabled="isDisabled"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ContactStatus" label="银行开户账号">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.BankAccountOpen" :disabled="isDisabled"></el-input>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-collapse-item>
                        <el-collapse-item title="合同正文和附件" name="formItem3">
                            <div class="h-table-list menulist">
                                <div class="h-scroll">
                                    <div class="table-s">
                                        <el-form-item v-if="!isDisabled">
                                            <upload ref="upload" @currentFile="onCurrentFile" :disabled="isDisabled"></upload>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-table :data="dataSource.AttachmentList" border style="width: 100%">
                                                <el-table-column width="55" type="index" label="序号">
                                                </el-table-column>
                                                <el-table-column prop="FileName" label="合同名称">
                                                </el-table-column>
                                                <el-table-column prop="CreatedUserName" label="上传人">
                                                </el-table-column>
                                                <el-table-column prop="CreatedTime" label="上传时间">
                                                </el-table-column>
                                                <el-table-column label="操作">
                                                    <template slot-scope="scope">
                                                        <el-button type="primary" size="small" style="margin-left:10px;" @click="fileDownload(scope.row)">下载
                                                        </el-button>
                                                        <el-button type="primary" size="small" v-if="!isDisabled" style="margin-left:10px;" @click="fileRemove(scope.$index)">删除
                                                        </el-button>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>

                </el-form>
            </el-tab-pane>
            <el-tab-pane label="关联项目" name="tab2">
                <ProjectList ref="projectList" :contractData="dataSource" :isDisabled="isDisabled">
                </ProjectList>
            </el-tab-pane>
        </el-tabs>
    </div>
    <DialogEx :options="staffOptions" title="关联组织" style="height:100%;" @onOk="onStaffOk">
        <Staff ref="staff" :config="staffConfig" @onSelectedRow="onStaffSelectedRow">
        </Staff>
    </DialogEx>
    <DialogEx :options="orgOptions" title="我方签订单位" style="height:100%;" @onOk="onOrgOk">
        <Organization ref="organization" :config="staffConfig" @onSelectedRow="onOrgSelectedRow">
        </Organization>
    </DialogEx>
    <DialogEx :options="customerOptions" title="客户选择" style="height:100%;" @onOk="onCustomerOk">
        <CustomerRelation ref="customerrelation" :config="staffConfig" @onSelectedRow="onCustomerSelectedRow">
        </CustomerRelation>
    </DialogEx>
</div>
</template>

<script>
import sha from '../../../../scripts/sha.js';
export default {
    data() {
        var checkPhone = function (rule, value, callback) {
            if (!value) return callback(new Error('员工手机号不能为空！'));
            var phoneReg = /^1[3-578]\d{9}$/;
            if (!phoneReg.test(value)) {
                return callback(new Error('手机号格式不正确！'));
            }
            callback();
        };
        var checkEmail = function (rule, value, callback) {
            var mailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if (value && !mailReg.test(value)) {
                return callback(new Error('邮箱格式不正确！'));
            }
            callback();
        };
        return {
            multipleSelection: [],
            dataSourceCopy: {},
            loadding: false,
            isDisabled: false,
            tabActiveName: 'tabBaice',
            currentEnterpriseSelectedRows: [],
            relationOptions: {
                visible: false,
                size: 'large'
            },
            checkRule: {
                Code: [{
                    required: true,
                    message: '请输入合同编码',
                    trigger: 'blur'
                }],
                Name: [{
                    required: true,
                    message: '请输入合同名称',
                    trigger: 'blur'
                }],
                PaymentType: [{
                    required: true,
                    message: '请选择收付类型',
                    trigger: 'blur'
                }],
                Type: [{
                    required: true,
                    message: '请选择合同类型',
                    trigger: 'blur'
                }],
                SigningDate: [{
                    required: true,
                    message: '请输入合同签订日期',
                    trigger: 'blur'
                }],
                StartDate: [{
                    required: true,
                    message: '请输入合同开始日期',
                    trigger: 'blur'
                }],
                EndDate: [{
                    required: true,
                    message: '请输入合同结束日期',
                    trigger: 'blur'
                }],
                OurIdentity: [{
                    required: true,
                    message: '请输入我方身份',
                    trigger: 'blur'
                }],
                SigningUnitName: [{
                    required: true,
                    message: '请选择我方签订单位',
                    trigger: 'blur'
                }],
            },
            projectData: [],
            formItems: ['formItem', 'formItem2', 'formItem3'],
            typeList: [{
                    label: '甲方',
                    value: '10'
                },
                {
                    label: '乙方',
                    value: '20'
                }
            ],
            staffOptions: {
                visible: false,
                size: 'large'
            },
            staffConfig: {
                isDetailDisplay: false,
                isSelectionDisplay: false,
            },
            staffSelecteData: {},
            orgOptions: {
                visible: false,
                size: 'large'
            },
            orgSelecteData: {},
            customerOptions: {
                visible: false,
                size: 'large'
            },
            customerSelecteData: {},
            crRowIndex: {},
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    mounted() {
        this.Event.$on("clearEditUserForm", () => this.resetForm);
    },
    watch: {
        dataSource: {
            handler(curVal, oldVal) {
                this.isDisabled = !this.dataSource.IsEdit;
                if (curVal.IsSuperAdmin) {}
            },
            deep: true
        },
    },
    methods: {
        tab2Click: function (tab, event) {},
        handleSelectionChange(val) {

            if (val.length > 0)
                val.forEach((item, index) => item.Checked = true);
            else
                this.dataSource.Roles.forEach((item, index) => item.Checked = false);
        },
        add() {
            this.Event.$emit("onAdd");
        },
        resetForm(id) {
            !this.isPassValidate && this.$refs['_contractInfoForm'].resetFields(); //清空表单
            this.$refs.projectList.getProjectList(id);
        },
        //保存
        save() {
            var _this = this;
            _this.$refs["_contractInfoForm"].validate((valid) => {
                _this.isPassValidate = valid;
                if (valid) {
                    if (_this.dataSource.CrList.filter(m => !m.CustomerName).length > 0) {
                        return this.Utils.messageBox("客户不能为空");
                    }
                    var routeName = _this.dataSource.Id ===null ? "create" : "edit";
                    _this.$ajax.send("omsapi/contractinfo/"+routeName, "post", _this.dataSource, (data) => {
                        _this.dataSource.IsEdit = false;
                        _this.dataSource.Id = data.Result;
                        _this.$parent.syncDataSource();
                        _this.Event.$emit("reloadPageList", data.Result);
                        _this.Utils.messageBox("保存成功.", "success");
                    });
                } else {
                    return false;
                }
            });
        },

        currencyChange(val) {
            this.dataSource.Currency = val;
        },
        typeChange(val) {
            this.dataSource.Type = val;
        },
        paymentTypeChange(val) {
            this.dataSource.PaymentType = val;
        },
        isFormalContractChange(val) {
            this.dataSource.IsFormalContract = val;
        },
        onSigningUserClick() {
            this.staffSelecteData = {};
            this.staffOptions.visible = true;
        },
        fileDownload(row) {
            var _this = this;
            var urlFile = "omsapi/files/download?file=" + row.FileName + "&url=" + row.AttachmentUrl + "&token=" + sha.getToken();
            _this.Utils.exportUrl(urlFile);
        },
        fileRemove(index) {
            var _this = this;
            var row = this.dataSource.AttachmentList[index];
            if (_this.dataSource.Id !== null && row.AttachmentId != null) {
                _this.$ajax.query(window.AppConfig.apiBaseUrl + "omsapi/sysattachmentclient/delete", "post", row, (data) => {
                    if (data.IsSuccess) {
                        _this.Utils.messageBox("移除成功.", "success");
                        this.dataSource.AttachmentList.splice(index, 1);
                    } else {
                        _this.Utils.messageBox("移除失败，清重试.", "warning");
                    }
                });
            } else {
                this.dataSource.AttachmentList.splice(index, 1);
            }
        },
        onStaffSelectedRow(val) {
            this.staffSelecteData = val;
        },
        onStaffOk() {
            this.dataSource.SigningUserId = this.staffSelecteData.Id;
            this.dataSource.SigningUserName = this.staffSelecteData.Name;
            this.staffOptions.visible = false;
        },
        onSigningUnitClick() {
            if (this.isDisabled)
                return;
            this.orgSelecteData = {};
            this.orgOptions.visible = true;
        },
        onOrgSelectedRow(val) {
            this.orgSelecteData = val;
        },
        onOrgOk() {
            this.dataSource.SigningUnitId = this.orgSelecteData.Id;
            this.dataSource.SigningUnitName = this.orgSelecteData.Name;
            this.orgOptions.visible = false;
        },
        onCustomerClick(index) {
            if (this.isDisabled)
                return;
            this.customerSelecteData = {};
            this.crRowIndex = index;
            this.customerOptions.visible = true;
        },
        onCustomerSelectedRow(val) {
            this.customerSelecteData = val;
        },
        onCustomerOk() {
            this.dataSource.CrList[this.crRowIndex].CustomerId = this.customerSelecteData.Id;
            this.dataSource.CrList[this.crRowIndex].CustomerCode = this.customerSelecteData.Code;
            this.dataSource.CrList[this.crRowIndex].CustomerName = this.customerSelecteData.Name;
            this.orgOptions.visible = false;
        },
        onCurrentFile(val) {
            var item = {
                FileName: val.FileName,
                AttachmentUrl: val.Url
            };
            this.dataSource.AttachmentList.push(item);
        }
    },
    components: {
        "upload": resolve => {
            require(['@/components/common/uploadlist/uploadlist.vue'], resolve)
        },
        "Staff": resolve => {
            require(['@/components/business/selector/staffselector.vue'], resolve)
        },
        "Organization": resolve => {
            require(['@/components/business/selector/organizationselector.vue'], resolve)
        },
        "CustomerRelation": resolve => {
            require(['@/components/business/selector/customerrelationselector.vue'], resolve)
        },
        "ProjectList": resolve => {
            require(['./projectlist.vue'], resolve)
        },
    }
}
</script>

<style></style>
